// extracted by mini-css-extract-plugin
export var roomBookButton = "room-module--room-book-button--fuZbU";
export var roomBookDetails = "room-module--room-book-details--Ww7RL";
export var roomBookInfo = "room-module--room-book-info--ONLfZ";
export var roomBookPrices = "room-module--room-book-prices--ehCy+";
export var roomCalendar = "room-module--room-calendar--YnGcS";
export var roomContent = "room-module--room-content--KdEdh";
export var roomDetails = "room-module--room-details--U7pE7";
export var roomFullDetails = "room-module--room-full-details--rSznU";
export var roomHeader = "room-module--room-header--2fmmT";
export var roomImage = "room-module--room-image--bw05r";
export var roomLegend = "room-module--room-legend--RDqw0";
export var roomText = "room-module--room-text--wR42X";