import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import ReactMarkdown from "react-markdown";
import ImageCarousel from "../utils/ImageCarousel";
import RoomLegend from "../components/rooms/roomLegend";

import {
  roomBookButton,
  roomBookPrices,
  roomBookInfo,
  roomBookDetails,
  roomLegend,
  roomCalendar,
  roomFullDetails,
  roomText,
  roomImage,
  roomDetails,
  roomContent,
  roomHeader,
} from "../css/room.module.css";

const Room = ({ data }) => {
  const {
    strapiRoom: {
      RoomTitle,
      roomstext,
      roomsRichText,
      roomImage: image,
      roomMultiImageFile,
      bookOnline,
      floorPlanLink,
      availabilityCalendarLink,
      hireAgreementLink,
      bookingStatus,
      prices,
    },
  } = data;

  return (
    <>
      <div className={roomHeader}>
        <h1>Our rooms</h1>
        <h3>{RoomTitle}</h3>
      </div>
      <div className={roomContent}>
        <div className={roomDetails}>
          <div className={roomImage} style={{ height: "100%" }}>
            {roomMultiImageFile.length > 0 ? (
              <ImageCarousel
                roomImages={[image, ...roomMultiImageFile]}
                RoomTitle={RoomTitle}
              />
            ) : (
              <GatsbyImage
                image={image.childImageSharp.gatsbyImageData}
                alt={RoomTitle}
                imgStyle={{ objectFit: "cover" }}
                style={{ height: "100%" }}
              />
            )}
          </div>
          <div className={roomText}>
            <p>{roomstext}</p>
            <div className={roomFullDetails}>
              <ReactMarkdown source={roomsRichText} />
            </div>
          </div>
          {availabilityCalendarLink !== null && (
            <React.Fragment>
              <div className={roomCalendar}>
                <iframe
                  src={availabilityCalendarLink}
                  title="room calendar"
                ></iframe>
              </div>
              <div className={roomLegend}>
                <RoomLegend />
              </div>
            </React.Fragment>
          )}
        </div>
        <div className={roomBookDetails}>
          <div className={roomBookInfo}>
            {bookOnline && (
              <div className={roomBookPrices}>
                <h3>Prices</h3>
                <ul>
                  {prices.map((item, i) => (
                    <li key={i}>
                      {item.bookingType.name} use: £{item.cost} per hour
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div>
              <h3>Floorplans</h3>
              <p>
                <a href={floorPlanLink}>
                  Download all the floorplans for {RoomTitle}
                </a>
                &nbsp;(PDF)
              </p>
            </div>
            <div>
              <h3>Hire agreement</h3>
              <p>
                <a href={hireAgreementLink}>
                  Download Pannal Village Hall hire agreement
                </a>
                &nbsp;(PDF)
              </p>
            </div>
            {!bookOnline && (
              <div>
                <h3>Booking requests</h3>
                <p>{bookingStatus}</p>
              </div>
            )}
          </div>
          {bookOnline && (
            <div className={roomBookButton}>
              <div>
                <Link to="/contact" className="btn-grey">
                  Contact us to book
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Room;

export const query = graphql`
  query room($slug: String!) {
    strapiRoom(slug: { eq: $slug }) {
      id
      slug
      RoomTitle
      roomstext
      roomsRichText
      roomImage {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
      roomMultiImageFile {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
      roomMultipleImage {
        id
        formats {
          medium {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
      }
      bookOnline
      bookingStatus
      availabilityCalendarLink
      floorPlanLink
      hireAgreementLink
      prices {
        cost
        bookingType {
          name
        }
      }
    }
  }
`;
